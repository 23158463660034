import React  from 'react';
import {createMuiTheme, makeStyles, ThemeProvider, useTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {BrowserRouter as Router, Redirect, Switch} from 'react-router-dom';

import {AssetFinder, PrivateRoute} from './components';

import {AppState} from './appSlice';
import { Box } from '@material-ui/core';
import { useAuth0 } from './auth';
import Topbar from "./components/menus/topbar";
import DataWrapper from "./pages/data/DataWrapper";
import ModelWrapper from "./pages/model/ModelsWrapper";
import AlertsWrapper from "./pages/alerts/AlertsWrapper";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    graphs: any,
    fontStyling: any,
    table: any,
  }
  interface ThemeOptions {
    graphs: any,
    fontStyling: any,
    table: any,
  }
}



const useStyles = makeStyles(theme => ({
  appRoot: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    flexGrow: 1
  },
  pageOffset: theme.mixins.toolbar,
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden'
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    position: 'relative',
    padding: theme.spacing(1)
  },
}));

interface Props {
  appState: AppState,
  setAssetFilters: Function
}

function App(props: Props) {
  const {
    user,
    isLoading,
    isAuthenticated,
  } = useAuth0();
  const {
    appState,
    setAssetFilters,
  } = props;
  const classes = useStyles();
  const {
    assetFilters,
  } = appState;

  const theme = useTheme();
  // This sets Material UI to use it's preset dark theme
  const muiTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#24B898',
      },
      error: {
        main: '#f44336'
      },
      text: {
        primary: '#e4e4e5'
      },
    },
    graphs: {
      borderRadius: 10,
      marginRight: 20,
      marginLeft: 40,
      chartBackground: '#262728',
      appText: '#e4e4e5',
      dimmerText: 'rgba(228, 228, 229, 0.5)',
      chartPlotBackground: '#191a1b',
      dimText: 'rgba(228, 228, 229, 0.75)',
      buttonBase: '#5a5a60',
      buttonShadow: '#505156',
      buttonHighlight: '#6c6e74',
      fontSize: '1rem',
      // Generated via https://medialab.github.io/iwanthue/ with ranges of H: 0-360; C 25-100; L 25-100
      series: [
        "#ff83f1",
        "#4cd600",
        "#8500c1",
        "#e6ff42",
        "#005bfa",
        "#9effa0",
        "#0137bf",
        "#c58200",
        "#5d85ff",
        "#ff6845",
        "#0085aa",
        "#aa0014",
        "#007f40",
        "#760088",
        "#fff5a9",
        "#a80078",
        "#ffd182",
        "#45476f",
        "#ffb5db",
        "#ae7d67"
      ],
      annotations: [
        '#ff2600',
        '#ff4d00',
        '#ff6b00',
        '#ff9100',
        '#ffc700'
      ]
    },
    fontStyling: {
      title: {
        fontSize: '1.31rem',
        padding: `${theme.spacing(2)}px 0`,
        fontWeight: 'bold',
      },
      table: {
        fontSize: '0.875rem'
      },
      alertTitle: {
        fontSize: '1rem'
      },
    },
    table: {
      tablePadding: {
        paddingLeft: theme.spacing(1),
      },
      cellPadding: {
        padding: `${theme.spacing(0.5)}px 0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      },
      header: {
        fontSize: '0.875rem',
        padding: `${theme.spacing(2)}px 0 0 ${theme.spacing(1)}px`,
        fontWeight: 'bold',
      }
    }
  });

  const pages: object = {
    alerts:       <AlertsWrapper assetFilters={assetFilters} alertType={"Alerts"}/>,
    maintenance:       <AlertsWrapper assetFilters={assetFilters} alertType={"Maintenance"}/>,
    production:       <AlertsWrapper assetFilters={assetFilters} alertType={"Production"}/>,
    data:         <DataWrapper assetFilters={assetFilters} />,
    models:       <ModelWrapper assetFilters={assetFilters} />
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box className={classes.appRoot}>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <Router basename='/'>
            <div className={classes.pageRoot} style={{ height: `100%` }}>
              <Topbar pages={Object.keys(pages)}/>
              <div className={classes.pageContent}>
                {!isLoading && user && isAuthenticated
                  ? <AssetFinder setAssetFilters={setAssetFilters} />
                  : null
                }
                <Box m={.5}/>
                <Switch>

                  <PrivateRoute exact path='/' component={
                    <Redirect to={'/alerts'}/>
                  } />

                  {Object.entries(pages).map(([page, component]) => (
                    <PrivateRoute
                      key={page.toString()}
                      exact path={'/'+page.toString()} component={component}
                    />
                  ))}

                </Switch>
              </div>
            </div>
          </Router>
        </ThemeProvider>
      </Box>
    </MuiPickersUtilsProvider>
  );
}

export default App;
