import React, {useEffect} from "react";
import {Route} from "react-router-dom";
import {useAuth0} from "../../auth";

interface Props {
  path: string,
  exact?: boolean,
  component?: any
}

function PrivateRoute(props: Props) {
  const {
    exact,
    path,
    component = null
  } = props;
  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        redirect_uri: window.location.origin,
      })
    }
  }, [user, isLoading, isAuthenticated, loginWithRedirect, path]);

  return <Route exact={exact} path={path} render={() =>
    isAuthenticated ? component : null
  }/>;
}

export default PrivateRoute;
