import React, {useEffect, useState} from 'react';
import AlertTable from "./components/alertTable";
import {
  Alert,
  Asset,
  DataPoint,
  MaintenanceData,
  Model,
  MODEL_TYPE,
  ModelGroup,
  Variable,
  VariableData
} from "../../models";
import {Box, useMediaQuery} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import {
  getAlert,
  getWaterfallData, queryAlertStatusHistory,
  queryDiagnosticVariables,
  queryLiveVariableData,
  queryMaintenanceData,
  queryMaintenanceDataFieldOptions,
  queryModelOutput,
  queryVariables
} from "../../api";
import {addDays, fromUnixTime, getTime, subDays} from "date-fns";
import AlertDetail from "./components/alertDetail";
import {AlertsState} from "./AlertsSlice";

interface Props {
  alertsState: AlertsState,
  setAlert: Function,
  setData: Function,
  initTimeRange: Function,
  updateTimeRange: Function,
  resetState: Function
  assetFilters: Asset[],
  alertType: string
}

function AlertsPage(props: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    alertsState,
    setAlert,
    setData,
    initTimeRange,
    updateTimeRange,
    resetState,
    assetFilters,
    alertType
  } = props;

  const {
    alert
  } = alertsState;

  const [selectedAlertId, setSelectedAlertId] = useState(0);

  useEffect(() => {
    setSelectedAlertId(0);
    resetState({});
    // eslint-disable-next-line
  }, [assetFilters]);

  useEffect(() => {
    if (selectedAlertId) {
      resetState({});
      getAlertData(selectedAlertId);
    }
    // eslint-disable-next-line
  }, [selectedAlertId]);

  return (
    <>
      <AlertTable
        onRowClick={onRowClick}
        assetFilters={assetFilters}
        search={!isMobile}
        paging
        rowSelected={selectedAlertId}
        alertType={alertType}
      />
      <Box m={1}/>
      {
        (alert && selectedAlertId > 0) &&
        <AlertDetail
          alertsState={alertsState}
          updateTimeRange={updateTimeRange}
          setAlert={setAlert}
        />
      }
    </>
  );

  function getVariables(
    variables: Array<Variable>,
    rangeStart: number,
    rangeEnd: number
  ): Promise<Array<VariableData>> {
    const queries = variables.map(
    variable => queryLiveVariableData({
        variable: variable.uid,
        timestamp__gte: rangeStart,
        timestamp__lte: rangeEnd,
        resolution: '1h'
      }).then(response => {
        return {
          name: variable.name,
          data: response.map(datapoint => [
            datapoint.timestamp * 1000,
            datapoint.value
          ] as DataPoint)
        }
      })
    );
    return Promise.all(queries);
  }

  function getModelOutputs(modelTypes: Array<Model>, timeRangeMin: number): Promise<Array<any>> {
    return Promise.all(
      modelTypes.map(modelType => {
        return queryModelOutput({
          model_type: modelType.id,
          timestamp__gte: timeRangeMin/1000
        }).then(modelOutputs => {
          if (!modelOutputs || modelOutputs.length === 0) {
            return;
          }
          return {
            name: modelType.name,
            data: modelOutputs.map(result => [result.timestamp * 1000, result.value]),
          }
        });
      })
    ).then(responses => responses.filter(r => !!r))
  }

  function getMaintenanceData(assets: Array<Asset>, alert: Alert): Promise<Array<MaintenanceData>> {
    return Promise.all(
      assets.map(asset => queryMaintenanceData({
        alert_id: alert.id,
        timestamp: alert.last_timestamp,
        asset__path__descendant_of_included: asset.path
      }
      )
      )
    ).then(responses => responses.flat())
  }

  function getWorkOrderTypes(): Promise<Array<string>> {
    return queryMaintenanceDataFieldOptions('type')
  }

  type ModelMap = {
    flagger: Array<Model>,
    decision: Array<Model>,
    detection: Array<Model>,
  }
  function mapModels(modelGroup: ModelGroup): ModelMap {
    return modelGroup.model_types.reduce<ModelMap>(
      (map: ModelMap, type: Model) => {
        if (type.name.includes(MODEL_TYPE.FLAGGER)) {
          map.flagger.push(type);
        } else if (type.name.includes(MODEL_TYPE.DECISION)) {
          map.decision.push(type);
        } else {
          map.detection.push(type);
        }
        return map;
      }, {
        decision: [],
        detection: [],
        flagger: []
      });
  }

  function getAlertData(id: number) {
    // clear possible previous alert
    setAlert(undefined);
    // get the flag event
    getAlert(id).then(alert => {

      setAlert(alert);

      const timeRangeMin = getTime(subDays(fromUnixTime(alert.start_timestamp), 90));
      const timeRangeStart = getTime(subDays(fromUnixTime(alert.start_timestamp), 30));
      let timeRangeMax = getTime(Date.now()); //now
      if (alert.end_timestamp) {
          timeRangeMax = getTime(addDays(fromUnixTime(alert.end_timestamp), 14));
      }

      initTimeRange({
        min: timeRangeMin,
        start: timeRangeStart,
        end: timeRangeMax,
        max: timeRangeMax,
      });

      queryDiagnosticVariables({
        diagnostic_data__model_input_id: alert.model_input,
        diagnostic_data__timestamp: alert.last_timestamp
      }).then(response => setData({label: 'diagnosticVariables', data: response}));

      let modelGroup = alert.model_group;
      const models = mapModels(modelGroup);

      getWaterfallData(id)
        .then(response => setData({label: 'waterfallData', data: response}));

      getModelOutputs(models.flagger, timeRangeMin)
        .then(response => setData({label: 'flaggerScores', data: response}));

      getModelOutputs(models.decision, timeRangeMin)
        .then(response => setData({label: 'decisionScores', data: response}));

      getModelOutputs(models.detection, timeRangeMin)
        .then(response => setData({label: 'detectionScores', data: response}));

      getMaintenanceData(modelGroup.assets, alert)
        .then(response => setData({label: 'maintenanceData', data: response}));

      getWorkOrderTypes()
        .then(response => setData({label: 'workOrderTypes', data: response}));

      queryVariables({ uid__in: modelGroup.variable_uids })
        .then(variables => getVariables(variables, timeRangeMin / 1000, timeRangeMax / 1000))
        .then(response => setData({label: 'variablesData', data: response}))

      queryAlertStatusHistory({
          flag_event__model_input__asset__path__descendant_of_included: modelGroup.assets.map(asset => asset.path).join(),
          timestamp__gte: timeRangeMin/1000
        })
        .then(response => setData({label: 'statusHistory', data: response}))

    }).catch(error => {
      // TODO: This should be logged to Sentry rather than the local console
      console.error('Request for alert data failed.');
      console.error(error);
    });
  }

  function onRowClick(id: number){
    if (id === selectedAlertId){
      setSelectedAlertId(0)
    } else {
      setSelectedAlertId(id)
    }
  }
}

export default AlertsPage;
